import { IconButton, Box, AppBar, useMediaQuery, Toolbar, styled, Stack } from '@mui/material';

import { IconMenu2 } from '@tabler/icons-react';
import Profile from './Profile';

import Navigation from './Navigation';
import { RootState, useAppDispatch, useAppSelector } from '@shared/store';
import { actions } from '@shared/store/ducks';
import BreadcrumbV2 from '../../shared/breadcrumb/BreadcrumbV2';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const { t } = useTranslation();

  // drawer
  const BCrumb = useAppSelector((state: RootState) => state.breadcrumbs);
  const customizer = useAppSelector((state: RootState) => state.customizer);
  const dispatch = useAppDispatch();

  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
    [theme.breakpoints.up('lg')]: {
      minHeight: customizer.TopbarHeight,
    },
  }));
  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.secondary,
  }));

  return (
    <AppBarStyled position="sticky" color="default">
      <ToolbarStyled>
        {/* ------------------------------------------- */}
        {/* Toggle Button Sidebar */}
        {/* ------------------------------------------- */}
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={
            lgUp
              ? () => dispatch(actions.customizer.toggleSidebar())
              : () => dispatch(actions.customizer.toggleMobileSidebar())
          }>
          <IconMenu2 size="20" />
        </IconButton>
        {BCrumb.iShown ? <BreadcrumbV2 title={t(BCrumb.pageTitle)} /> : null}

        {/* ------------------------------------------- */}
        {/* Search Dropdown */}
        {/* ------------------------------------------- */}
        {/* <Search /> */}
        {lgUp ? (
          <>
            <Navigation />
          </>
        ) : null}

        <Box flexGrow={1} />
        <Stack spacing={1} direction="row" alignItems="center">
          {/* <Language /> */}
          {/* ------------------------------------------- */}
          {/* Ecommerce Dropdown */}
          {/* ------------------------------------------- */}
          {/* <Cart /> */}
          {/* ------------------------------------------- */}
          {/* End Ecommerce Dropdown */}
          {/* ------------------------------------------- */}
          {/* <Notifications /> */}
          {/* ------------------------------------------- */}
          {/* Toggle Right Sidebar for mobile */}
          {/* ------------------------------------------- */}
          {/* {lgDown ? <MobileRightSidebar /> : null} */}
          <Profile />
        </Stack>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default Header;
