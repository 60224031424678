import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { CampaignList } from './CampaignList';
import PageContainer from '@shared/components/container/PageContainer';
import Welcome from '@shared/layouts/FullLayout/shared/welcome/Welcome';
import Spinner from '@shared/components/spinner/Spinner';
import { useAppDispatch, useAppSelector } from '@shared/store';
import { actions } from '@shared/store/ducks';

import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { useStyles } from './styles';
import { FilterCard } from '@shared/components/filterCard/FilterCard';
import { useWebSocketGetCampaigns } from '@features/Admin/Campaign/hooks/useWebSocketGetCampaigns';
import { ResponseMsg } from '@shared/types/admin/agent';
import CustomAlert from '@shared/components/alert/alert';

export const CampaignPage: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data: campaignList, isSuccess, isLoading } = useWebSocketGetCampaigns();
  const dispatch = useAppDispatch();
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [responseMsg, setResponseMsg] = useState<ResponseMsg>({
    message: '',
    type: 'success',
  });

  const currentCompany = useAppSelector((state) => state.auth.authenticatedUserCompany);

  useEffect(() => {
    if (responseMsg.message) {
      const timer = setTimeout(() => {
        setResponseMsg({ message: '', type: 'success' });
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [responseMsg]);

  useEffect(() => {
    const firstLogin = localStorage.getItem('firstLogin');

    if (!firstLogin) {
      localStorage.setItem('firstLogin', 'true');
      setIsFirstLogin(true);
    } else {
      setIsFirstLogin(false);
    }
    dispatch(actions.customizer.toggleLayout('full'));
  }, [dispatch]);

  const BCrumb = [
    {
      to: AuthenticatedPath.CAMPAIGN,
      title: t('campaignPage.header.campaign'),
    },
  ];

  const setLoading = async (flag: boolean) => {
    setLoadingFlag(flag);
  };

  const onCampaignDeleteError = (error: ResponseMsg) => {
    setResponseMsg(error);
  };

  if (isLoading && !isSuccess) {
    return <Spinner />;
  }

  return (
    <>
      <PageContainer
        title={`${currentCompany?.title || ''} ${t('adminMainPage.header.homePage')}`}
        description={`${currentCompany?.title || ''} ${t('adminMainPage.header.homePage')}`}>
        {/* <Breadcrumb
          title={t('adminMainPage.header.companyName', { companyName: currentCompany?.title })}
          items={BCrumb}>
          <></>
        </Breadcrumb> */}
        <Box className={classes.main}>
          {responseMsg.message ? (
            <CustomAlert message={responseMsg.message} type={responseMsg.type} />
          ) : (
            ''
          )}
          <FilterCard />
          <CampaignList
            setLoading={setLoading}
            onCampaignDeleteError={onCampaignDeleteError}
            campaignList={campaignList ? campaignList : []}
          />
        </Box>
        {isFirstLogin ? <Welcome /> : <></>}
      </PageContainer>
    </>
  );
};
