import React from 'react';
import { Typography, IconButton, Stack, useTheme, Box } from '@mui/material';
import { CampaignDto } from '@shared/services/apiService/apiService';
import { IconActionPlay, IconActionStop } from '@shared/assets/images/svgs';
import { getCampaignStatus } from './utils';
import i18n from '@shared/utils/i18n';

export enum CampaignStatus {
  NotStarted = 'not-started',
  Dialing = 'dialing',
  Stopping = 'stopping',
}

interface CampaignStatusButtonProps {
  campaign?: CampaignDto | null;
  disabled?: boolean;
  onClick?: (id: string, campaignStatus: CampaignStatus) => void;
  statusLabels?: Record<string, string>;
}

const DEFAULT_STATUS_MAPPINGS: Record<string, string> = {
  stopText: 'Stop',
  startText: i18n.t('adminMainPage.operation.start'),
  stop: i18n.t('adminMainPage.operation.stop'),
  stopping: i18n.t('adminMainPage.operation.stopping'),
};

const getCampaignStatusFragment = (
  campaignStatus: CampaignStatus,
  { stoppingText } = DEFAULT_STATUS_MAPPINGS,
  disabled: boolean = false,
) => {
  const theme = useTheme();
  const color = theme.palette.extendedGrey?.dark;
  if (campaignStatus === CampaignStatus.Dialing) {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          boxShadow: `0px 10px 20px ${theme.palette.extendedGrey?.neutral}1A`,
          backgroundColor: 'transparent',
          border: `1px soled ${theme.palette.extendedGrey?.lighter}`,
          width: 30,
          height: 30,
          '&:hover': {
            backgroundColor: theme.palette.extendedGrey?.lightest,
          },
        }}>
        <IconActionStop />
      </Box>
    );
  } else if (campaignStatus === CampaignStatus.NotStarted) {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          boxShadow: `0px 1px 4px ${theme.palette.extendedGrey?.light}`,
          backgroundColor: 'transparent',
          width: 30,
          height: 30,
          '&:hover': {
            backgroundColor: theme.palette.extendedGrey?.lightest,
          },
        }}>
        <IconActionPlay />
      </Box>
    );
  } else if (campaignStatus === CampaignStatus.Stopping) {
    return <Typography fontWeight="bold">{stoppingText}</Typography>;
  }
};

const CampaignStatusButton: React.FC<CampaignStatusButtonProps> = ({
  campaign,
  disabled,
  onClick,
  statusLabels = {},
}) => {
  const campaignStatus = getCampaignStatus(campaign?.campaignRun);

  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(campaign?.id as string, campaignStatus);
      }}>
      <Stack alignItems="center" direction="row" gap={1}>
        {getCampaignStatusFragment(campaignStatus, statusLabels, disabled)}
      </Stack>
    </IconButton>
  );
};

export default CampaignStatusButton;
