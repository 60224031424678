import { createSlice } from '@reduxjs/toolkit';

export interface BreadCrumbType {
  subtitle?: string;
  items?: any[];
  title: string;
  children?: JSX.Element;
}

type InitStateType = {
  pageTitle: string;
  path: BreadCrumbType[];
  iShown: boolean;
};

const initialState: InitStateType = {
  pageTitle: 'campaignPage.header.campaign',
  path: [
    {
      title: 'campaignPage.header.campaign',
    },
  ],
  iShown: false,
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumb: (state, action) => {
      state.path = action.payload;
    },
    setIsShownBreadcrumbs: (state, action) => {
      state.iShown = action.payload;
    },
  },
});

export const actions = {
  ...breadcrumbsSlice.actions,
};

export const { reducer } = breadcrumbsSlice;
