import { ConversationSentimentDto } from '@shared/services/apiService/apiService';

export const getCallDuration = (answeredAt: string, terminatedAt: string) => {
  const answeredAtTime = new Date(answeredAt).getTime();
  const terminatedAtTime = new Date(terminatedAt).getTime();

  const durationSec = Math.floor((terminatedAtTime - answeredAtTime) / 1000);
  const hours = String(Math.floor(durationSec / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((durationSec % 3600) / 60)).padStart(2, '0');
  const seconds = String(durationSec % 60).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const getCurrentCallDuration = (answeredAt: string): string => {
  const answeredAtTime = new Date(answeredAt).getTime();
  const currentTime = Date.now();

  const durationSec = Math.floor((currentTime - answeredAtTime) / 1000);
  const hours = String(Math.floor(durationSec / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((durationSec % 3600) / 60)).padStart(2, '0');
  const seconds = String(durationSec % 60).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const getMedianCallDurationInMin = (callDuration?: number | null): string => {
  if (!callDuration) {
    return 'N/A';
  }
  const roundedValue = Math.round(callDuration);

  const hours = String(Math.floor(roundedValue / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((roundedValue % 3600) / 60)).padStart(2, '0');
  const secs = String(roundedValue % 60).padStart(2, '0');

  return `${hours}:${minutes}:${secs}`;
};

export function getTotalSentimentsScore(sentimentData: ConversationSentimentDto[]): number {
  return sentimentData.reduce((acc, data) => {
    return (
      acc +
      data.sentimentScores.reduce((innerAcc, sentiment) => {
        switch (sentiment.label) {
          case 'positive':
            return innerAcc + sentiment.score;
          case 'neutral':
            return innerAcc + sentiment.score * 0;
          case 'negative':
            return innerAcc - sentiment.score;
          default:
            return innerAcc;
        }
      }, 0)
    );
  }, 0);
}

export function getSentimentsColor(sentimentData: ConversationSentimentDto[]): string {
  const totalScore = getTotalSentimentsScore(sentimentData);

  const totalSentimentScoresCount = sentimentData.reduce((count, data) => {
    return count + data.sentimentScores.length;
  }, 0);

  const averageScore = totalSentimentScoresCount > 0 ? totalScore / totalSentimentScoresCount : 0;

  if (averageScore > 0) {
    return 'green'; // Positive average
  } else if (averageScore === 0) {
    return 'gray'; // Neutral average
  } else {
    return 'red'; // Negative average
  }
}

export const formatInteractionResult = (input: string): string => {
  return input.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};
