import { Grid, Typography, useTheme } from '@mui/material';

interface BreadCrumbProps {
  subtitle?: string;
  title: string;
}

const BreadcrumbV2 = ({ subtitle, title }: BreadCrumbProps) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={6}
        lg={8}
        mb={1}
        sx={{
          display: 'flex',
          margin: 0,
        }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: '21px',
            fontWeight: 500,
            color: theme.palette.text.primary,
          }}>
          {title}
        </Typography>
        {subtitle ? (
          <Typography color="textSecondary" variant="h6" fontWeight={400} mt={0.8} mb={0}>
            {subtitle}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default BreadcrumbV2;
