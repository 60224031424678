import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import { IconChevronDown } from '@shared/assets/images/svgs';
import { useStyles } from '../styles';
import { useCreateCampaignV2 } from '@features/Admin/Campaign/hooks/useCreateCampaignV2';
import { FormFields } from '@shared/types/admin/campaign';
import Spinner from '@shared/components/spinner/Spinner';
import { CampaignType } from '@shared/services/apiService/apiService';
import { CampaignTypeEnum } from '@shared/constants/campaign.constants';

interface NewCampaignDialogProps {
  showDialogFlag: boolean;
  handleClose: (value: React.SetStateAction<boolean>) => void;
  showDialog: (value: React.SetStateAction<boolean>) => void;
}

export const NewCampaignDialog: React.FC<NewCampaignDialogProps> = ({
  showDialogFlag,
  handleClose,
  showDialog,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [campaignName, setCampaignName] = useState<string>('');
  const [campaignType, setCampaignType] = useState<CampaignType | undefined>(undefined);
  const [campaignNameError, setCampaignNameError] = useState('');

  const { onSubmit, setValue, control, isLoading } = useCreateCampaignV2();

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValue(name as FormFields, value);

    if (name === 'title') {
      setCampaignName(value);
    }
  };

  const handleCampaignTypeChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setCampaignType(value as CampaignType);

    setValue(name as FormFields, value);
  };

  const handleSubmit = () => {
    if (!campaignName) {
      setCampaignNameError('Add campaign name');
    } else {
      onSubmit();
      resetModal();
    }
  };

  const resetModal = () => {
    setCampaignName('');
    setCampaignType(undefined);
    setCampaignNameError('');
    showDialog(false);
  };

  return (
    <Dialog
      open={showDialogFlag}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // TODO: "backdropFilter" may not work in firefox
      sx={{
        backdropFilter: 'blur(5px)',
        '[aria-labelledby=alert-dialog-title]': {
          borderRadius: '12px',
          maxHeight: '335px',
          minWidth: '470px',
        },
      }}>
      <DialogTitle
        fontSize="21px"
        fontWeight="500"
        id="alert-dialog-title"
        sx={{
          padding: '30px 30px 24px 30px',
        }}>
        {t('adminMainPage.operation.addCampaign')}
      </DialogTitle>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <DialogContent
            sx={{
              padding: '0 30px 24px',
            }}>
            <Typography
              variant="subtitle1"
              fontWeight={400}
              component="label"
              htmlFor="campaign-title"
              sx={{
                mt: 0,
                display: 'block',
              }}>
              {t('campaignPage.campaignName')}
            </Typography>
            <CustomTextField
              control={control}
              name="title"
              onChange={handleOnChangeInput}
              value={campaignName}
              id="campaign-title"
              variant="outlined"
              placeholder={t('campaignPage.placeholders.campaignName')}
              sx={{
                '#campaign-title::placeholder': {
                  color: theme.palette.extendedGrey?.neutral,
                  opacity: 1,
                },
              }}
              fullWidth
              required
            />

            {!campaignName && (
              <Typography component="p" sx={{ color: '#FF0000', width: '300px' }}>
                {campaignNameError}
              </Typography>
            )}

            <Typography
              variant="subtitle1"
              fontWeight={400}
              component="label"
              htmlFor="campaign-title"
              sx={{
                mt: '24px',
                display: 'block',
              }}>
              {t('campaignPage.campaignType')}
            </Typography>
            <CustomSelect
              control={control}
              labelId="campaign-type"
              name="type"
              onChange={handleCampaignTypeChange}
              id="campaign-type-id"
              value={campaignType}
              IconComponent={IconChevronDown}
              className={classes.campaign_type_select}
              displayEmpty
              renderValue={(value: unknown) => {
                if (!value) {
                  return (
                    <Typography color={theme.palette.extendedGrey?.neutral}>
                      {t('campaignPage.placeholders.campaignType')}
                    </Typography>
                  );
                }
                return <>{value}</>;
              }}>
              <MenuItem key={CampaignTypeEnum.INBOUND} value={CampaignTypeEnum.INBOUND}>
                {CampaignTypeEnum.INBOUND}
              </MenuItem>
              <MenuItem key={CampaignTypeEnum.OUTBOUND} value={CampaignTypeEnum.OUTBOUND}>
                {CampaignTypeEnum.OUTBOUND}
              </MenuItem>
            </CustomSelect>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 30px 30px',
            }}>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {t('adminMainPage.operation.ok')}
            </Button>
            <Button
              onClick={() => {
                showDialog(false);
                resetModal();
              }}>
              {t('adminMainPage.operation.cancel')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
